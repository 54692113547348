// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-data-use-js": () => import("./../src/pages/data-use.js" /* webpackChunkName: "component---src-pages-data-use-js" */),
  "component---src-pages-how-to-js": () => import("./../src/pages/how-to.js" /* webpackChunkName: "component---src-pages-how-to-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ios-js": () => import("./../src/pages/ios.js" /* webpackChunkName: "component---src-pages-ios-js" */),
  "component---src-pages-subscription-confirmed-js": () => import("./../src/pages/subscription-confirmed.js" /* webpackChunkName: "component---src-pages-subscription-confirmed-js" */),
  "component---src-pages-subscription-error-js": () => import("./../src/pages/subscription-error.js" /* webpackChunkName: "component---src-pages-subscription-error-js" */),
  "component---src-pages-terms-js": () => import("./../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-unsubscribed-js": () => import("./../src/pages/unsubscribed.js" /* webpackChunkName: "component---src-pages-unsubscribed-js" */)
}

