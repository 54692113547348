module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icons/icon.png","name":"Memo Reminders","short_name":"Reminders","start_url":"/","background_color":"#ffffff","theme_color":"#607d8b","display":"minimal-ui"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-N2FK7R8","includeInDevelopment":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://85012cab933e485db7b44f6eab353470@sentry.datanasov.com/2","environment":"production","enabled":true},
    }]
